$(document).ready(function () {

    ////////////////////////////////////////////////
    //		CART WIDGET


    $('.cart-trigger-dummy').on('click', function (ev) {
        ev.preventDefault();
        $('.cart-box').css({'z-index': 201}).addClass('hovered');
        $('#cart-widget-overlay').show();
        $('.cart-trigger-dummy').hide();
    });

    $('#cart-widget-overlay').on('click', function (ev) {
        ev.preventDefault();
        $(this).hide();
        $('.cart-trigger-dummy').show();
        $('.cart-box').removeClass('hovered').css({'z-index': 51});
    });

    ////////////////////////////////////////////////
    //		AD LABELS

    $('.ad-wrapper').prepend('<div class="ad-label">Hirdetés</div>');

    //////////////////////////////////////////////////

    ////////////////////////////////////////////////
    //		PRODUCT CAROUSEL
    /*
     var productOwl = $('.product-carousel');
     carouselChangeSpeed = 6000;

     productOwl.owlCarousel({
     responsive: true,
     navigation : true,
     pageination: false,
     singleItem : true,
     transitionStyle : false,
     autoPlay: false,
     afterAction: function(){

     var currentSlide = parseInt(this.owl.visibleItems)+1;
     $(this.owl.baseElement).find('.owl-item:nth-child('+currentSlide+') img.lazyOwl').each(function(){
     $(this).attr('src', $(this).data('src')).removeClass('lazyOwl');;
     });
     },
     afterInit:  function(){

     var currentSlide = parseInt(this.owl.visibleItems)+1;
     $(this.owl.baseElement).find('.owl-item:nth-child('+currentSlide+') img.lazyOwl').each(function(){
     $(this).attr('src', $(this).data('src')).removeClass('lazyOwl');;
     });
     }
     });
     */


    //////////////////////////////////////////////////

    ////////////////////////////////////////////////
    //		BODY COLORING

    /*
     var currentTime = new Date()
     var hours = currentTime.getHours()
     if (hours < 8){
     $('body').addClass('evening');
     };
     if (hours >= 8 && hours < 12){
     $('body').addClass('morning');
     };

     if (hours >= 12 && hours < 18){
     $('body').addClass('afternoon');
     };

     if (hours >= 18){
     $('body').addClass('prevning');
     };*/

    /*var randColor = '#'+Math.floor(Math.random()*16777215).toString(16);
     $('body').css({'background-color': randColor});*/

    //////////////////////////////////////////////////

    ////////////////////////////////////////////////
    //		MODAL

    /*

     // javascripttel modal megnyitasa
     wpModal({
     Mtype: 'ajax',		// lehet null es ajax
     MURL: 'URL',		// az url lehet ajax hivashoz path vagy hashmarkkal kezdve a DOM-ban levo elem ID-je
     Mhide: 5000,		// automata eltuntetes milisecundomban, lehet null
     Mtitle: 'String',	// ha Mtype ures, akkor itt meg lehet adni a modal cimet
     Mtext: 'String'		// ha Mtype ures, akkor itt meg lehet adni a modal tartalmat
     });

     // modal eltuntetese
     closeWPModal();

     // loader megjelenitese
     showWPLoader();

     // loader eltuntetese
     hideWPLoader();
     */
    // modalnyitas rakotese gombra
    $('.trigger-modal').on('click', function (ev) {
        wpModal({Mtype: 'ajax', MURL: $(this).attr('href')});
        return false;
    });

    $('body').on('click', '#wpoverlay', function (ev) {
        ev.preventDefault();
        closeWPModal();
    });

    //wpModal({Mtitle:"title",Mtext:"Lorem ipsum dolor sit amet"});


    //////////////////////////////////////////////////

    ////////////////////////////////////////////////
    //		CHECKBOX

    if ($('input[type=checkbox]').length > 0) {
        $('input[type=checkbox]').each(function (index, element) {
			var chkClasses = '';
			
            if ($(this).prop('checked')) {
                chkClasses += ' checked ';
            }
			if ($(this).prop('disabled')) {
                chkClasses += ' disabled ';
            }
			
			$(this).wrap('<span class="chk ' + chkClasses + '" />'); 
        });
        $('input[type=checkbox]').on('change', function () {
            $(this).prop('checked') ? $(this).parent().addClass('checked') : $(this).parent().removeClass('checked')
        }).on('click', function () {
            $(this).trigger('change');
        });
    }

    //////////////////////////////////////////////////

    ////////////////////////////////////////////////
    //		INC AND DEC AMOUNT

    $('.inc-amount').on('click', function (ev) {
        ev.preventDefault();
        var $amountInput = $(this).parent().parent().parent().find('.amount-input-wrap input');
        $amountInput.val(parseInt($amountInput.val()) + 1);
    });

    $('.dec-amount').on('click', function (ev) {
        ev.preventDefault();
        var $amountInput = $(this).parent().parent().parent().find('.amount-input-wrap input');

        if ($amountInput.val() > 1) {
            $amountInput.val(parseInt($amountInput.val()) - 1);
        }
    });

    //////////////////////////////////////////////////

    ////////////////////////////////////////////////
    //		RADIO

    if ($('input[type=radio]').length > 0) {
        makeRadio();
    }

    ////////////////////////////////////////////////
    //		SHOW MINI MENU

    cSelect();

    $('.form-row').on('click', '.customSelect .customSelectList a', function (ev) {

        ev.preventDefault();
        $(this).parent().siblings('select').children('option').removeAttr('selected');
        $(this).parent().slideUp('fast').siblings('select').val($(this).attr('href')).trigger("change").siblings('.customSelectToggle').html($(this).html());

    });

    $('.form-row').on('click', '.customSelect .customSelectToggle', function (ev) {
        $(this).siblings('.customSelectList').slideDown('fast').addClass('open');

    });

    $('.form-row').on('mouseleave', '.customSelect .customSelectToggle', function () {
        $(this).siblings('.customSelectList').delay(100).slideUp('fast', function () {
            $(this).removeClass('open');
        });
    });

    $('.form-row').on('mouseenter', '.customSelect .customSelectList', function () {

        $(this).stop(true, true);
    });

    $('.form-row').on('mouseleave', '.customSelect .customSelectList', function () {

        $(this).stop().slideUp('fast', function () {
            $(this).removeClass('open')
        });
    });

    $('.form-row').on('mousewheel DOMMouseScroll', '.customSelect .customSelectList', function (e) {
        var e0 = e.originalEvent,
            delta = e0.wheelDelta || -e0.detail;

        this.scrollTop += ( delta < 0 ? 1 : -1 ) * 30;
        e.preventDefault();
    });


    ////////////////////////////////////////////////
    //		EQ PRODUCT CAROUSEL

    $('.product-carousel .owl-item').each(function () {
        var maxItemH = 0;
        $('.pi-eq', this).each(function () {
            var tempH = $(this).height();
            if (tempH > maxItemH) {
                maxItemH = tempH;
            }
            ;
        });

        $('.pi-eq', this).css({height: maxItemH});
    });

    ////////////////////////////////////////////////
    //		EQ NEWS TEASER

    $('.news-teaser-list').each(function () {
        var maxItemH = 0;
        $('.nt-eq', this).each(function () {
            var tempH = $(this).height();
            if (tempH > maxItemH) {
                maxItemH = tempH;
            }
            ;
        });

        $('.nt-eq', this).css({height: maxItemH});
    });

    ////////////////////////////////////////////////
    //		CART CLICKABLE


    $('.cart-page-wrapper').on('click', '.cart-step-header.clickable', function (ev) {
        ev.preventDefault();
        openTab($(this).data('open'));
    });


});

// helper function to determin scrolled from top

function f_scrollTop() {
    return f_filterResults(
        window.pageYOffset ? window.pageYOffset : 0,
        document.documentElement ? document.documentElement.scrollTop : 0,
        document.body ? document.body.scrollTop : 0
    );
}

function f_filterResults(n_win, n_docel, n_body) {
    var n_result = n_win ? n_win : 0;
    if (n_docel && (!n_result || (n_result > n_docel)))
        n_result = n_docel;
    return n_body && (!n_result || (n_result > n_body)) ? n_body : n_result;
}


// MAKE RADIO BUTTONS

function makeRadio() {

    $('input[type=radio]:not(".done")').each(function (index, element) {
		var chkClasses = '';
			
		if ($(this).prop('checked')) {
			chkClasses += ' checked ';
		}
		if ($(this).prop('disabled')) {
			chkClasses += ' disabled ';
		}
		
		$(this).wrap('<span class="chk' + chkClasses + '" />');
    });
    $('input[type=radio]:not(".done")').addClass('done');
    $('input[type=radio].done').on('change', function () {
        $('input[name=' + $(this).attr('name') + ']').parent().removeClass('checked');
        $(this).parent().addClass('checked');
    }).on('click', function () {
        $(this).trigger('change');
    });
}

// CHANGE TABS

function openTab(tabID) {
    $('.clickable').removeClass('clickable');
    for (i = 1; i < tabID; i++) {
        $('#cart-step-' + i + ' .cart-step-header').addClass('clickable');
    }

    if (formValidate(tabID - 1) == true) {
        //saveData();


        if (tabID == 4) {
            jQuery.ajax({
                type: "GET",
                url: 'cart/summary',
                data: $('#cartForm').serialize(),
                success: function (result) {
                    data = jQuery.parseJSON(result);
                    if (data['type'] == 'used') {
                        wpModal({
                            Mtype: 'ajax', // lehet null es ajax
                            MURL: 'auth/login-modal', // az url lehet ajax hivashoz path vagy hashmarkkal kezdve a DOM-ban levo elem ID-je
                            Mhide: null // automata eltuntetes milisecundomban, lehet null
                        });
                    }
                },
            });
        }
        if (tabID == 5) {
            copys(document.getElementById('o_copy'));
            //window.location.replace('cart/finish');

            jQuery.ajax({
                type: "GET",
                url: 'cart/summary',
                data: $('#cartForm').serialize(),
                success: function (result) {
                    data = jQuery.parseJSON(result);

                    /*
                     if ($('#btn5').hasClass("reg") && !$("#o_save").is(":checked") && $('#ifd').val() == 0) {
                     wpModal({
                     Mtype: 'ajax', // lehet null es ajax
                     MURL: 'flag_ajax.php', // az url lehet ajax hivashoz path vagy hashmarkkal kezdve a DOM-ban levo elem ID-je
                     Mhide: null // automata eltuntetes milisecundomban, lehet null
                     });
                     }
                     else {*/
                    $('.cart-step-body').addClass('hidden');
                    $('#cart-step-' + tabID + '-body').removeClass('hidden');


                    //=========SUMMARY=====
                    var bname = $("#o_bill_name").val();
                    if (bname == '') {
                        bname = $("#o_first_name").val() + ' ' + $("#o_last_name").val();
                    }
                    data = jQuery.parseJSON(result);
                    $("#cart-summary-wrap").html(data['html']);
                    $("#total-rows").html(data['totalRows']);
                    $("#mainData").html($("#o_first_name").val() + ' ' + $("#o_last_name").val() + '<br />' + $("#o_email").val() + '<br />' + $("#o_phone1").val() + $("#o_phone2").val());
                    $("#billingData").html('<p class="mb-0">' + bname + '</p><p class="mb-0">' + $("#o_zip").val() + ' ' + $("#o_city").val() + '</p><p class="mb-0">' + $("#o_street").val() + ' ' + $("#o_housenr").val() + '<br />' + $("#o_adoszam").val() + '</p>');
                    $("#deliveryData").html(data['deliveryData']);
                    $("#receptionData").html(data['receptionData']);
                    $("#paymodeData").html(data['paymodeData']);
                    // }


                }
            });

        } else {
            $('.cart-step-body').addClass('hidden');
            $('#cart-step-' + tabID + '-body').removeClass('hidden');
            if (tabID == 1) {
                $('#cart-step-2-body').removeClass('hidden');
            }
        }


    }
    ;

    if (tabID < 3) {
        $('.cart-step-body').addClass('hidden');
        $('#cart-step-1-body, #cart-step-2-body').removeClass('hidden');
    }
	
	// go to next point
	if ( tabID > 1 ) {
		var targetTabId = tabID-1;
	} else {
		var targetTabId = tabID;
	}
	$('html, body').animate({
		scrollTop: $('#cart-step-' + targetTabId ).offset().top
	}, 'slow');


}

function regCheckBeforeTab5() {
    jQuery.ajax({
        type: "GET",
        url: 'auth/email-check',
        data: {email: $('#o_email').val()},
        success: function (result) {
            result = jQuery.parseJSON(result);
            console.log(result);
            if (!result.isError) {
                openTab(5);
            } else {
                wpModal({
                    Mtype: null, // lehet null es ajax
                    Mtext: result.errorMessage, // az url lehet ajax hivashoz path vagy hashmarkkal kezdve a DOM-ban levo elem ID-je
                    Mhide: null // automata eltuntetes milisecundomban, lehet null
                });
            }
        }
    });
	
	$('html, body').animate({
		scrollTop: $('#cart-step-4').offset().top
	}, 'slow');
}

// CHANGE CAROUSEL TABS

function changePCTab($this) {

    $($this).siblings('a').removeClass('selected');
    $($this).addClass('selected');

    $('#pctab-' + $($this).attr('href')).siblings('.pctab-body').addClass('hidden');
    $('#pctab-' + $($this).attr('href')).removeClass('hidden');

    return false;
}

// TOGGLE ORDER HISTORY

function toggleOrder(tabID) {
    $('#order-history .cart-content:not("#orderHistory-' + tabID + '")').addClass('hidden');
    $('#orderHistory-' + tabID).toggleClass('hidden');
}

// TABBED OFFER LIST CHANGE

function tabbedOffer(tabID) {
    //$('.tabbed-offer-item:not("#tabbedOffer-'+ tabID +'")').removeClass('open');
    $('#tabbedOffer-' + tabID).toggleClass('open');
}
// MODAL WITH STATIC TEXT

var modalHeader = '<div class="modal-header clear"><h3>';
var modalHeaderEnd = '</h3></div>';
var modalBody = '<div class="modal-body clear"><div class="a-center clear">';
var modalBodyEnd = '</div></div>';
var inlineFlag, modalSource;

function wpModal(options) {

    $('#wploader, #wpoverlay').stop(true).fadeIn(100);

    $('#wpmodal').fadeOut(600, function () {
        $('#wploader').fadeIn(200, function () {

            switch (options.Mtype) {

                case 'ajax' :

                    if (options.MURL.charAt(0) == '#') { // inline

                        inlineFlag = 1;
                        modalSource = options.MURL;

                        $('#wpmodal-content').html($(options.MURL).html());
                        $(options.MURL).html('');

                        var modalTop = f_scrollTop() + $(window).height() / 2 - $('#wpmodal').outerHeight() / 2;

                        $('#wpmodal').css({top: modalTop > 50 ? modalTop : 100});

                        $('#wploader').fadeOut(400, function () {
                            $('#wpmodal').fadeIn(600);
                        });
                    }
                    else { // external

                        inlineFlag = 0;
                        modalSource = '';

                        $.ajax({
                            type: "GET",
                            url: options.MURL,
                            success: function (html) {
                                $('#wpmodal-content').html(html);

                                var modalTop = f_scrollTop() + $(window).height() / 2 - $('#wpmodal').outerHeight() / 2;
                                $('#wpmodal').css({top: modalTop > 50 ? modalTop : 100});

                                $('#wploader').fadeOut(400, function () {
                                    $('#wpmodal').fadeIn(600);
                                });
                            }
                        });
                    }
                    break;

                default:

                    inlineFlag = 0;
                    modalSource = '';


                    $('#wpmodal-content').html((typeof options.Mtitle !== 'undefined' ? modalHeader + options.Mtitle + modalHeaderEnd : '') + (typeof options.Mtext !== 'undefined' ? modalBody + options.Mtext + modalBodyEnd : ''));

                    var modalTop = f_scrollTop() + $(window).height() / 2 - $('#wpmodal').outerHeight() / 2;
                    $('#wpmodal').css({top: modalTop > 50 ? modalTop : 100});

                    $('#wploader').fadeOut(400, function () {
                        $('#wpmodal').fadeIn(600);
                    });
            }
            ;


        });
    });

    if (options.Mhide > 0) {
        setTimeout("closeWPModal()", options.Mhide);
    }
};

function closeWPModal() {
    $('#wpoverlay, #wpmodal, #wploader').hide();
    if (inlineFlag) {
        $(modalSource).html($('#wpmodal-content').html())
    }
    ;

}

function showWPLoader() {
    $('#wpoverlay, #wploader').show();
}

function hideWPLoader() {
    $('#wpoverlay, #wploader').hide();
}


function cSelect() {

    $('.form-row select:not(".done")').each(function (index, element) {

        var $elem = $(this);

        $elem.addClass('done');

        var htmlHelper = '<a href="javascript:;" class="customSelectToggle"></a><div class="customSelectList">';

        $("option", this).each(function () {

            htmlHelper += '<a href="' + this.value + '">' + this.text + '</a>';

        });

        htmlHelper += '</div>';

        $elem.wrap('<div class="customSelect" id="select_' + $elem.attr("id") + '">').parent('.customSelect').append(htmlHelper).children('.customSelectToggle').html($("option:selected", this).text());


    });


}

function listenStock(uuid) {
    $.ajax({
        url: "product/listen-stock/" + uuid,
        data: {},
        success: function (result) {

            if (result == '1') {
                wpModal({
                    Mtitle: "",
                    Mtext: "Ez a könyv bekerült a készletfigyeléseid közé! E-mailban értesítünk a könyv beszerzéséről!"
                });
            }
            if (result == "2") {
                wpModal({Mtitle: "", Mtext: "Ezt a könyvet már betetted a készletfigyeléseid közé!"});
            }
            if (result == "3") {
                wpModal({Mtitle: "", Mtext: "A készletfigyeléshez jelentkezz be!"});
            }

        }
    });
}

function reloadCartPartial() {
    var partial = $('#cart-partial');
    var addClass = false;
    if (partial == null || partial == undefined) return;
    $.ajax({
        url: 'cart/partial',
        success: function (result) {

            if ($(".cart-box").hasClass("hovered")) {
                addClass = true;
            }

            partial.html(result);

            if (addClass) {
                $('.cart-box').css({'z-index': 201}).addClass('hovered');
                $('#cart-widget-overlay').show();
                $('.cart-trigger-dummy').hide();
            }
        }

    });

}

function addToCart(url, popup, tag) {
    if (popup == null || popup == undefined) popup = true;
    $.ajax({
        url: url,
        data: [],
        success: function (result) {
            //data = jQuery.parseJSON(result);
            data = result;
            if (data['success']) {
                var qtyinp = "." + data['id'] + '_qtyinp';

                $(qtyinp).val(data['quantity']);
                $('.' + data['id'] + '_qty').html(data['quantity']);
                $('.' + data['id'] + '_total').html((data['quantity'] * data['price']) + " Ft");
                $(".cart-qty-" + tag).html(data['total_qty']);
                $('.cart_discount_sum-' + tag).html(data['cart_discount_sum'] + ' Ft');
                $('.cart_sum-' + tag).html(data['cart_sum'] + ' Ft');
                $('.total_price-' + tag).html(data['total_price'] + data['shipping'] + ' Ft');
                $('.shipping_fee-' + tag).html(data['shipping'] + ' Ft');
                $('.shipping_left-' + tag).html(data['shipping_left'] + ' Ft');
                $('#cart-discounts').html(data['discounts']);
                $('#cart-total-cost-rows').html(data['total_cost_rows']);

                /*
                 $("#cart-box-content #cart-items-header").html(data['header_html']);
                 $("#cart-box-content #cart-items").html(data['html']);
                 $("#cart-qty").html("(" + data['qty'] + ")");*/

                $('#cart-content-step1').html(data['cartpage']);

                if (data['reload'] == 1) {
                    location.reload();
                }
                else {
                    if (popup) {
                        wpModal({
                            Mtype: null,
                            Mtext: data['popup'],
                            Mhide: null
                        });
                    }
                }

                reloadCartPartial();
            } else {
                if (typeof  data['errorMsg'] !== 'undefined'  && data['errorMsg'] !== ''){
                    wpModal({
                        Mtype: null,
                        Mtext: data['errorMsg'],
                        Mhide: null
                    });
                }
            }
        }
    });
}


function setUtalvanyValue(value, id) {
    if (value == 0) {
        return false;
    }
    $.ajax({
        url: "get-gift-card-button",
        data: {id: 1, ut_value: value},
        success: function (result) {
            data = jQuery.parseJSON(result);

            addToCart(data.url);
        }
    });
}

function addToCartUtalvany() {
    wpModal({Mtitle: "", Mtype: 'ajax', MURL: 'create-gift-card'});
}

function removeFromCart(id) {
    $.ajax({
        url: "cart/clear/" + id,
        data: {id: id},
        success: function (result) {
            data = result;


            $("#cart-box-content #cart-items-header").html(data['header_html']);
            $("#cart-box-content #cart-items").html(data['html']);
            $("#cart-qty").html("(" + data['qty'] + ")");

            $('#cart-content-step1').html(data['cartpage']);

            if (data['reload'] == 1) {
                location.reload();
            }

            if (data['kupon_error'] != '') {
                $('.redeem-kupon-list').hide();
                wpModal({Mtitle: "", Mtext: data['kupon_error'], Mhide: 10000,});
            }
        }
    });
}
function plus(id, quantity, tag) {
    var url = "cart/add/" + id + "/" + quantity;
    addToCart(url, false, tag);
    return;
    $.ajax({
        url: "cart/add/" + id + "/" + quantity,
        data: {},
        success: function (result) {
            data = result;
            //$("#cart-box-content #cart-items-header").html(data['header_html']);
            //$("#cart-box-content #cart-items").html(data['html']);
            $("#cart-qty").html("(" + data['quantity'] + ")");

            $('#cart-content-step1').html(data['cartpage']);
            //checkCartPrice(data['price'],data['db'],data['db_utalvany']);
            if (data['reload'] == 1) {
                location.reload();
            }
            else {
                if (data['keszlethiany'] == 1) {
                    wpModal({Mtitle: "", Mtext: "Ebből a könyvből nincsen több példány!", Mhide: 5000,});
                }
            }
        }
    });
}
function minus(id, quantity, tag) {
    var url = "cart/remove/" + id + "/" + quantity;
    addToCart(url, false, tag);
    return;
    $.ajax({
        url: "cart/remove/" + id + "/" + quantity,
        data: {},
        success: function (result) {
            data = result;
            //$("#cart-box-content #cart-items-header").html(data['header_html']);
            //$("#cart-box-content #cart-items").html(data['html']);
            //$("#cart-qty").html("("+data['qty']+")");
            $('#cart-content-step1').html(data['cartpage']);

            checkCartPrice(data['price'], data['db'], data['db_utalvany']);

            if (data['reload'] == 1) {
                location.reload();
            }

            if (data['kupon_error'] != '') {
                $('.redeem-kupon-list').hide();
                //wpModal({Mtitle:"",Mtext:data['kupon_error'],Mhide: 10000,});
            }
        }
    });
}

function checkCartPrice(p, db, u) {


    if (p < 1 && u == db) {
        $('#cart-step-2').hide();
        $('#cart-step-3').hide();
        $('#btn-kosar').show();
        $('#btn-kosar').attr('onclick', 'openTab(4); return false;');
    } else if (p < 1 && u != db) {
        $('#cart-step-2').show();
        $('#cart-step-3').hide();
        $('#btn-kosar').hide();
        $('#btn0').attr('onclick', 'openTab(4); return false;');
    } else if (u == db && p > 0) {
        $('#cart-step-2').hide();
        $('#cart-step-3').show();
        $('#btn-kosar').show();
        $('#btn-kosar').attr('onclick', 'openTab(3); return false;');
    } else {
        $('#cart-step-2').show();
        $('#cart-step-3').show();
        $('#btn-kosar').hide();
        $('#btn0').attr('onclick', 'openTab(3); return false;');
    }


}

/**
 * @TODO: implement!
 * @param id
 * @param db
 */
function setQuantity(id, db, tag) {
    if ($.isNumeric(db)) {
        $.ajax({
            url: "cart/clear/" + id,
            data: {},
            success: function (result) {
                data = result;
                $("#cart-box-content #cart-items-header").html(data['header_html']);
                $("#cart-box-content #cart-items").html(data['html']);
                $("#cart-qty").html("(" + data['qty'] + ")");

                $('#cart-content-step1').html(data['cartpage']);
                checkCartPrice(data['price'], data['db'], data['db_utalvany']);
            /*    if (data['reload'] == 1) {
                    location.reload();
                }
                else {
                    if (data['keszlethiany'] == 1) {
                        wpModal({Mtitle: "", Mtext: "Ebből a könyvből nincsen több példány!", Mhide: 5000,});
                    } else {*/
                        plus(id, db, tag);
                  /*  }
                }*/
            }
        });

    }
    else {
        wpModal({Mtitle: "", Mtext: "A beviteli mezőbe csak számot írhatsz!"});
    }
}

function formValidate(step) {
    ertekek = [];
    var emsg = '';

    if (step == 2) {
        if ($('input[name=o_collectpoint]:checked', '#cartForm').val() == undefined) {
            wpModal({Mtitle: "", Mtext: "Szállítási mód kiválasztása kötelező!"});
            return false;
        }

        if ($('input[name=o_collectpoint]:checked', '#cartForm').val() == 2) {
            if ($("input[name=o_dpi_name]:checked").val() == undefined) {
                wpModal({Mtitle: "", Mtext: "Átvételi pont választása kötelező!"});
                return false;
            }
        }

        if ($('input[name=o_collectpoint]:checked', '#cartForm').val() == 3) {
            if ($("input[name=book24_pp]:checked").val() == undefined) {
                wpModal({Mtitle: "", Mtext: "Átvételi pont választása kötelező!"});
                return false;
            }
        }
    }

    if (step == 4) {
        var hiba = 0;

        if ($("#o_last_name").val() == "") {
            hiba++;
        }

        if ($("#o_first_name").val() == "") {
            hiba++;
        }


        if ($("#o_email").val() == "") {
            hiba++;
        }


        if ($("#o_phone1").val() == "") {
            hiba++;
        }
        if ($("#o_phone2").val() == "") {
            hiba++;
        }

        if ($("#o_city").val() == "") {
            hiba++;
        }

        if ($("#o_street").val() == "") {
            hiba++;
        }

        if ($("#o_housenr").val() == "") {
            hiba++;
        }

        if ($("#o_zip").val() == "") {
            hiba++;
        }

        if (!$('#o_copy').prop('checked') && $('input[name=o_collectpoint]:checked').val() == "1") {
            if ($("#o_delivery_name").val() == "") {
                hiba++;
            }
            if ($("#o_delivery_city").val() == "") {
                hiba++;
            }
            if ($("#o_delivery_street").val() == "") {
                hiba++;
            }
            if ($("#o_delivery_housenr").val() == "") {
                hiba++;
            }
            if ($("#o_delivery_zip").val() == "") {
                hiba++;
            }
        }

        if (hiba > 0) {
            wpModal({Mtitle: "", Mtext: "A *-gal jelölt mezők kitöltése kötelező!"});
            return false;
        }

        if (checkmailv($("#o_email").val()) == 'nok') {
            wpModal({Mtitle: "", Mtext: "Az e-mail cím formátuma helytelen!"});
            return false;
        }


        if (!$('#o_aszf').is(":checked")) {
            wpModal({Mtitle: "", Mtext: "Üzletszabályzat elfogadása kötelező!"});
            return false;
        }

        ertekek[0] = $("#o_surname").val();
        ertekek[2] = $("#o_phone1").val();
        ertekek[3] = $("#o_first_name").val();
        ertekek[4] = $("#o_city").val();
        ertekek[5] = $("#o_street").val();
        ertekek[6] = $("#o_housenr").val();
        ertekek[7] = $("#o_zip").val();
        ertekek[8] = $("#o_comp_building").val();
        ertekek[9] = $("#o_nem").val();
    }

    if (step == 3) {
        if ($('input[name=o_pay]:checked', '#cartForm').val() == undefined) {
            wpModal({Mtitle: "", Mtext: "Fizetési mód választása kötelező!"});
            return false;
        }
        if ($('input[name=o_collectpoint]:checked', '#cartForm').val() == 3) {
            if ($('input[name=o_pay]:checked', '#cartForm').val() == 1) {
                wpModal({Mtitle: "", Mtext: "Fizetési mód választása kötelező!"});
                return false;
            }
        }
        ertekek[0] = $('input[name=o_pay]:checked', '#cartForm').val();
    }

    //saveData(step,ertekek);
    return true;
}

function checkmailv(email) {
    /*
     var res = '';
     $.ajax({
     method: 'POST',
     url: "kosar.php",
     async: false,
     data: {emailcheck: email},
     success: function (result) {
     res = result;
     }
     });
     return res;
     */
}


function getPoints(city, defaultval) {
    if (city == 'Budapest') {
        $('#kerulet_valaszto').removeClass('hidden');
        return false;
    } else {
        $('#kerulet_valaszto').addClass('hidden');
    }
    $.ajax({
            url: "pickup-points",
            data: {city: city, defaultval: defaultval},
            success: function (result) {
                $("#deliveryPointList .dpi-list").html(result);
                makeRadio();
            }
        }
    );
}

function getDistrict(ker) {
    $.ajax({
        url: "pickup-points",
        async: false,
        data: {kerulet: ker, city: 'Budapest'},
        success: function (result) {
            $("#deliveryPointList .dpi-list").html(result);
            makeRadio();
        }
    });
}

function saveData() {
    $.ajax({
        type: "POST",
        url: "cart/update-checkout",
        data: $('#cartForm').serialize()
    });
}

function summary() {
    $.ajax({
        url: "summary.ajax.php",
        data: $('#cartForm').serialize(),
        type: 'GET',
        success: function (result) {
            var bname = $("#o_bill_name").val();
            if (bname == '') {
                bname = $("#o_surname").val() + ' ' + $("#o_firstname").val();
            }
            data = jQuery.parseJSON(result);
            $("#cart-summary-wrap").html(data['html']);
            $("#mainData").html($("#o_surname").val() + ' ' + $("#o_firstname").val() + '<br />' + $("#o_email").val() + '<br />' + $("#o_phone1").val() + $("#o_phone2").val());
            $("#billingData").html('<p class="mb-0">' + bname + '</p><p class="mb-0">' + $("#o_zip").val() + ' ' + $("#o_city").val() + '</p><p class="mb-0">' + $("#o_street").val() + ' ' + $("#o_housenr").val() + '<br />' + $("#o_adoszam").val() + '</p>');
            $("#deliveryData").html(data['deliveryData']);
            $("#receptionData").html(data['receptionData']);
            $("#paymodeData").html(data['paymodeData']);
			
			$('html, body').animate({
				scrollTop: $('#cart-step-4').offset().top
			}, 'slow');
        }
    });
}

function AjanlasTilt() {
    $.ajax({
        type: "POST",
        url: "konyv_ajanlas.php",
        data: {ajanlasTilt: 1}
    });
}

function setEditForm(address_id) {
    $.ajax({
        url: "profile/getAddress",
        data: {address_id: address_id},
        type: 'POST',
        success: function (result) {
            data = jQuery.parseJSON(result);
            if (data.id) {
                $("#a_type").parent().parent().remove();
                $("#a_name").val(data.name);
                $("#a_city").val(data.city);
                $("#a_zip").val(data.zip);
                $("#a_address").val(data.address);
                $("#a_address2").val(data.address2);
                $("#a_vat_number").val(data.vat_number);
                $("#address_id").val(data.id);
                $('#new-address').slideUp();
                $('#edit-address').slideDown();
            }
        }
    });
}

function delAddress(url) {
    if (confirm('Biztosan törlöd a címet?')) {
        $.ajax({
            url: url,
            data: {},
            success: function (result) {
                data = jQuery.parseJSON(result);
                $(".row-" + data.id).remove();
            }
        });
    }
}

function setAddress(id, type) {
    $.ajax({
        url: "profile/getAddress",
        data: {address_id: id},
        type: 'POST',
        success: function (result) {
            data = jQuery.parseJSON(result);
            if (data.id) {
                if (type == 'bill') {
                    $("#o_bill_name").val(data.name);
                    $("#o_city").val(data.city);
                    $("#o_zip").val(data.zip);
                    $("#o_street").val(data.address);
                    $("#o_housenr").val(data.address2);
                    $("#o_adoszam").val(data.vat_number);
                }

                if (type == 'ship') {
                    $("#o_delivery_name").val(data.name);
                    $("#o_delivery_city").val(data.city);
                    $("#o_delivery_zip").val(data.zip);
                    $("#o_delivery_street").val(data.address);
                    $("#o_delivery_housenr").val(data.address2);

                }

            }
        }
    });
}

